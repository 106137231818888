export default [
  {
    name: "UserProfile",
    path: "/user-profile",
    component: () => import("@/views/UserProfile"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
      title: "Informações do usuário",
    },
  },
  // ACCOUNT
  {
    name: "AccountGroupVersion",
    path: "/account-group-version",
    component: () => import("@/views/AccountGroupVersion"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "AccountGroupSystemId",
    path: "/account-group-system-id",
    component: () => import("@/views/AccountGroupSystemId"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "AccountGroup",
    path: "/account-group",
    component: () => import("@/views/AccountGroup"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "AccountGroupCalc",
    path: "/account-group-calc",
    component: () => import("@/views/AccountGroupCalc"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "ChartAccountGroupVersion",
    path: "/chart-account-group-version",
    component: () => import("@/views/ChartAccountGroupVersion"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "ChartAccountGroup",
    path: "/chart-account-group",
    component: () => import("@/views/ChartAccountGroup"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "AccountGroupReference",
    path: "/account-group-reference",
    component: () => import("@/views/AccountGroupReference"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "AccountGroupFilterVersion",
    path: "/account-group-filter-version",
    component: () => import("@/views/AccountGroupFilterVersion"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "AccountGroupFilter",
    path: "/account-group-filter",
    component: () => import("@/views/AccountGroupFilter"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },

  // CONSOLIDATION KEY
  {
    name: "ConsolidationKey",
    path: "/consolidation-key",
    component: () => import("@/views/ConsolidationKey"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ConsolidationKeyType",
    path: "/consolidation-key-type",
    component: () => import("@/views/ConsolidationKeyType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },

  //EVENT
  {
    name: "Event",
    path: "/event",
    component: () => import("@/views/Event"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "EventStatusByUser",
    path: "/event-status-by-user",
    component: () => import("@/views/EventStatusByUser"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  // REGISTER
  {
    name: "Bank",
    path: "/bank",
    component: () => import("@/views/Bank"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "BankAccountType",
    path: "/bank-account-type",
    component: () => import("@/views/BankAccountType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowCategoryType",
    path: "/cash-flow-category-type",
    component: () => import("@/views/CashFlowCategoryType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowTransfer",
    path: "/cash-flow-transfer",
    component: () => import("@/views/CashFlowTransfer"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowCategory",
    path: "/cash-flow-category",
    component: () => import("@/views/CashFlowCategory"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowAccountCategory",
    path: "/cash-flow-account-category",
    component: () => import("@/views/CashFlowAccountCategory"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "BankAccountInitialBalance",
    path: "/bank-account-initial-balance",
    component: () => import("@/views/BankAccountInitialBalance"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "BankAccountBalance",
    path: "/bank-account-balance",
    component: () => import("@/views/BankAccountBalance"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowValue",
    path: "/cash-flow-value",
    component: () => import("@/views/CashFlowValue"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowPrevision",
    path: "/cash-flow-prevision-value",
    component: () => import("@/views/CashFlowPrevision"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "BankAccount",
    path: "/bank-account",
    component: () => import("@/views/BankAccount"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "BankAccountChartAccount",
    path: "/bank-account-chart-account",
    component: () => import("@/views/BankAccountChartAccount"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  // OPERATION
  {
    name: "CashFlowErpAp",
    path: "/cash-flow-erp-ap",
    component: () => import("@/views/CashFlowErpAp"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowErpAr",
    path: "/cash-flow-erp-ar",
    component: () => import("@/views/CashFlowErpAr"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },

  // CAR/CAP 

  {
    name: "CashFlowReport",
    path: "/cash-flow-report",
    component: () => import("@/views/CashFlowReport"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowArReport",
    path: "/cash-flow-ar-report",
    component: () => import("@/views/CashFlowArReport"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowApReport",
    path: "/cash-flow-ap-report",
    component: () => import("@/views/CashFlowApReport"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashStatement",
    path: "/cash-statement",
    component: () => import("@/views/CashStatement"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },

  // CASH FLOW

  {
    name: "CashFlowDaily",
    path: "/cash-flow-daily",
    component: () => import("@/views/CashFlowDaily"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowMonthly",
    path: "/cash-flow-monthly",
    component: () => import("@/views/CashFlowMonthly"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowOverview",
    path: "/cash-flow-overview",
    component: () => import("@/views/CashFlowOverview"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowMProjections",
    path: "/cash-flow-monthly-projection",
    component: () => import("@/views/CashFlowMProjections"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowOperational",
    path: "/cash-flow-operational",
    component: () => import("@/views/CashFlowOperational"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowPlanning",
    path: "/cash-flow-planning",
    component: () => import("@/views/CashFlowPlanning"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
  {
    name: "CashFlowReports",
    path: "/cash-flow-reports",
    component: () => import("@/views/CashFlowReports"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    }
  },
]